import { Container, Grid } from '@mui/material';
import React, { useEffect, useState } from "react";
import { isAdmin, isManager } from '../../services/auth';

// import dayjs from 'dayjs';
import AccTable from "./accTable";
import Autocomplete from '@mui/material/Autocomplete';
import FormCard from "../../components/forms/formCard";
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import PageHeaderButton from '../../components/pageHeaderButton';
import Paper from '@mui/material/Paper';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { api } from '../../services/api';
import dayjs from 'dayjs';
// import OutlinedCard from "./card";
import { sumReceivedValues } from "../../common/utils";

const Acc = props => {
    const [allAccList, setAllAccList] = useState([]);
    const [accList, setAccList] = useState([]);
    const [srcAccList, setSrcAccList] = useState([]);
    const [field, setField] = useState("");
    const [valuesList, setValuesList] = useState([]);

    const mapFields = {
        "Banco": "razaoSocial",
        "Contrato": "contratoBanco",
        "Bacen": "contratoBacen",
        "Data": "dataContrato"
    }
    useEffect(() => {
        let mounted = true;
        api.getAccs()
            .then(resp => {
                if (mounted && resp) {
                    sumAllAccValues(resp.content);
                }
            })
        return () => mounted = false;
    }, [])

    const sumAllAccValues = (accs) => {
        let totalValue = 0;
        let totalReceived = 0;
        accs.forEach(acc => {
            let totalReceivedAcc = sumReceivedValues(acc.recebimentos);
            totalValue += acc.valorMonetarioDolar;
            totalReceived += totalReceivedAcc;

            acc.totalQuitado = totalReceivedAcc;
            acc.totalEmAberto = acc.valorMonetarioDolar - totalReceivedAcc;
        });
        setAccList(accs);
        setSrcAccList(accs);
        setAllAccList(accs);
        return [totalValue - totalReceived, totalValue, totalReceived];
    }

    const handleFieldSelect = (value) => {
        if (value) {
            let valuesFiltered = [];
            if (mapFields[value] === "razaoSocial") {
                valuesFiltered = srcAccList.map(acc => acc.banco.razaoSocial);
            } else {
                if (mapFields[value] === "dataContrato") {
                    valuesFiltered = srcAccList.map(acc => dayjs(acc[mapFields[value]]).format('DD/MM/YYYY').toString());
                } else {
                    valuesFiltered = srcAccList.map(acc => acc[mapFields[value]]);
                }
            }
            setValuesList(valuesFiltered.filter((item, index) => valuesFiltered.indexOf(item) === index));
            setField(mapFields[value]);
        }
    }

    const handleValueSelect = (value) => {
        if (value && value.length > 0) {
            let accFiltered = [];
            if (field === "razaoSocial") {
                accFiltered = srcAccList.filter(a => value.includes(a.banco.razaoSocial));
            }
            else {
                if (field === "dataContrato") {
                    accFiltered = srcAccList.filter(a => value.includes(dayjs(a[field]).format('DD/MM/YYYY').toString()));
                } else {
                    accFiltered = srcAccList.filter(a => value.includes(a[field]));
                }
            }
            setAccList(accFiltered);
        } else {
            setAccList(srcAccList);
        }
    }

    const handleChangePriority = (event) => {
        if (event.target.value === "open") {
            let filteredAcc = allAccList.filter(a => a.totalEmAberto > 0)
            setAccList(filteredAcc);
            setSrcAccList(filteredAcc);
        }
        if (event.target.value === "closed") {
            let filteredAcc = allAccList.filter(a => a.totalEmAberto <= 0)
            setAccList(filteredAcc);
            setSrcAccList(filteredAcc);
        }
        if (event.target.value === "all") {
            setAccList(allAccList);
            setSrcAccList(allAccList);
        }
    };

    return (
        <Container maxWidth="xg" sx={{ mt: 4, mb: 4 }}>
            <Grid item xs={12}>
                <PageHeaderButton
                    title="Adiantamentos de Contrato de Câmbio"
                    path="/accs"
                    canCreate={isAdmin() || isManager()}></PageHeaderButton>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Paper variant="outlined" sx={{ my: { xs: 3, md: 0 }, p: { xs: 2, md: 3 }, borderRadius: '10px' }}>
                        <Grid item xs={12} sm={12}>
                            <FormCard title="Filtros">
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={12}>
                                        <FormControl>
                                            <FormLabel id="demo-row-radio-buttons-group-label" sx={{ fontSize: "12px" }}>Considerar Acc's:</FormLabel>
                                            <RadioGroup
                                                row
                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                name="row-radio-buttons-group"
                                                onChange={handleChangePriority}
                                                defaultValue="open"
                                            >
                                                <FormControlLabel value="open" control={<Radio style={{ height: "5px" }} size='small' />}
                                                    label={<Typography style={{ fontSize: "12px" }}>Não Quitados</Typography>} />
                                                <FormControlLabel value="closed" control={<Radio size='small' />} label={<Typography style={{ fontSize: "12px" }}>Quitados</Typography>} />
                                                <FormControlLabel value="all" control={<Radio size='small' />} label={<Typography style={{ fontSize: "12px" }}>Todos</Typography>} />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={2}>
                                        <Autocomplete
                                            size="small"
                                            disablePortal
                                            id="field"
                                            getOptionLabel={(option) => option}
                                            options={Object.keys(mapFields)}
                                            onChange={(event, value) => handleFieldSelect(value)}
                                            sx={{ width: "100%" }}
                                            renderInput={(params) => <TextField
                                                {...params}
                                                InputProps={{ ...params.InputProps, style: { fontSize: "12px" } }}
                                                InputLabelProps={{ ...params.InputLabelProps, style: { fontSize: "12px" } }}
                                                label="Campo a ser filtrado" />}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={10}>
                                        <Autocomplete
                                            multiple
                                            size="small"
                                            disablePortal
                                            id="value"
                                            getOptionLabel={(option) => option}
                                            options={valuesList}
                                            onChange={(event, value) => handleValueSelect(value)}
                                            sx={{ width: "100%" }}
                                            renderInput={(params) => <TextField
                                                {...params}
                                                label="Conteúdo"
                                                InputProps={{ ...params.InputProps, style: { fontSize: "12px" } }}
                                                InputLabelProps={{ ...params.InputLabelProps, style: { fontSize: "12px" } }}
                                            />}
                                        />
                                    </Grid>
                                </Grid>
                            </FormCard>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <AccTable rows={accList} />
                </Grid>
            </Grid>
        </Container>
    );

};

export default Acc;
