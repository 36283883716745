import React, { useState, useEffect } from 'react';
import {
    Grid,
    Typography,
    Button,
    IconButton,
} from '@mui/material';
import TextField from '@mui/material/TextField';
import { red } from '@mui/material/colors'
import { Add, Remove } from '@mui/icons-material';
import UploadFileIcon from '@mui/icons-material/UploadFile';

import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

import { api } from '../../services/api';

import { getUserId } from '../../services/auth';

import PageCreate from '../../components/pageCreate';
import FormGeneralSales from '../../components/forms/formSales';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const createNewSale = async item => {
    const formData = new FormData();
    for (let i = 0; i < item.arquivos.length; i++) {
        if (item.arquivos[i]["file"] !== "" && item.arquivos[i]["field"] !== "") {
            formData.append(item.arquivos[i]["field"], item.arquivos[i]["file"]);
        }
    }
    const newItem = { ...item };
    newItem.totalDolar = realToFloat(newItem.totalDolar);
    newItem.totalReais = realToFloat(newItem.totalReais);
    newItem.taxaCambial = realToFloat(newItem.taxaCambial);
    newItem.entrada = dolarToFloat(newItem.entrada);
    newItem.usuario = getUserId();
    const jsonBody = JSON.stringify(newItem);
    const blob = new Blob([jsonBody], {
        type: 'application/json'
    });
    formData.append("body", blob);
    const resp = await api.postSale(formData);
    return resp;
}

function dolarToFloat(value) {
    let newValue = value.replaceAll(",", "");
    return parseFloat(newValue);
}

function realToFloat(value) {
    let newValue = value.replace(/\./g, "");
    newValue = newValue.replace(",", ".");
    return parseFloat(newValue);
}

export default function FinInstitutionCreate() {
    const base = {
        "totalReais": "0",
        "totalDolar": "",
        "taxaCambial": "0",
        "entrada": "0",
        "produto": "",
        "importadora": "",
        "exportadora": "",
        "code": "",
        "dataVenda": null,
        "arquivos": []
    }

    const [inputFields, setInputFields] = useState(base);

    const [running, setRunning] = useState(false);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("success");

    const [exporterList, setExporterList] = useState([]);
    const [importerList, setImporterList] = useState([]);
    const [productList, setProductList] = useState([]);

    useEffect(() => {
        let mounted = true;
        api.getExporters()
            .then(resp => {
                if (mounted && resp) {
                    if (resp.length === 1) {
                        setInputFields({ ...inputFields, exportadora: resp[0].id })
                    }
                    setExporterList(resp)
                }
            })
        api.getImporters()
            .then(resp => {
                if (mounted && resp) {
                    setImporterList(resp)
                }
            })
        api.getProducts()
            .then(resp => {
                if (mounted && resp) {
                    setProductList(resp)
                }
            })
        return () => mounted = false;
    }, [])

    const handleSubmit = async e => {
        setRunning(true);
        e.preventDefault();
        const resp = await createNewSale(inputFields);
        if (resp) {
            setMessage("Venda cadastrada com sucesso!");
            setSeverity("success");
        } else {
            setMessage("Erro ao cadastrar nova venda!");
            setSeverity("error");
        }
        setInputFields(base);
        setOpen(true)
    }

    const handleAddFile = (target, idx) => {
        let filesList = inputFields.arquivos;
        filesList[idx]["file"] = target.files[0];
        setInputFields((inputFields) => ({
            ...inputFields, arquivos: filesList
        }));
    };

    const handleAddName = (target, idx) => {
        let filesList = inputFields.arquivos;
        filesList[idx]["field"] = target;
        setInputFields((inputFields) => ({
            ...inputFields, arquivos: filesList
        }));
    };

    return (
        <PageCreate title="Nova Venda" handleSubmit={handleSubmit} message={message} severity={severity} running={running} setRunning={setRunning} open={open} setOpen={setOpen}>
            <FormGeneralSales inputFields={inputFields} setInputFields={setInputFields} running={running} exporter={exporterList[0]} clientList={importerList} productList={productList} />
            <Grid item xs={12} sm={12}>
                <Accordion expanded={true}>
                    <AccordionSummary expandIcon={false} id="panel-tel-header">
                        <Typography>Envio dos Arquivos</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12}>
                                <IconButton
                                    color="primary"
                                    aria-label="add file"
                                    size="small"
                                    onClick={() => {
                                        let filesList = inputFields.arquivos;
                                        filesList.push({ "field": "", "file": "" });
                                        setInputFields({ ...inputFields, arquivos: filesList });
                                    }}
                                >
                                    <Add />
                                </IconButton>
                                <IconButton
                                    sx={{ color: red[700] }}
                                    aria-label="rm file"
                                    size="small"
                                    onClick={() => {
                                        if (inputFields.arquivos.length > 1) {
                                            let filesList = inputFields.arquivos;
                                            filesList.pop();
                                            setInputFields({ ...inputFields, arquivos: filesList });
                                        }
                                    }}
                                >
                                    <Remove />
                                </IconButton>
                            </Grid>
                            {inputFields.arquivos.map((field, idx) => {
                                return (
                                    <React.Fragment key={idx}>
                                        <Grid item xs={12} sm={3}>
                                            <TextField
                                                fullWidth
                                                size="small"
                                                disabled={running}
                                                id={`${idx + 1}`}
                                                name="filename"
                                                label={`Arquivo ${idx + 1}`}
                                                value={inputFields.arquivos[idx]["field"]}
                                                onChange={event => handleAddName(event.target.value, idx)}
                                                InputLabelProps={{ shrink: true }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            <TextField
                                                fullWidth
                                                size="small"
                                                disabled={running}
                                                id={`${idx + 1}`}
                                                name="arquivo"
                                                label={`Arquivo ${idx + 1}`}
                                                value={inputFields.arquivos[idx]["file"].name}
                                                InputLabelProps={{ shrink: true }}
                                                InputProps={{
                                                    readOnly: true,
                                                    endAdornment:
                                                        <Button
                                                            size="small"
                                                            component="label"
                                                            color="primary"
                                                            variant="outlined">
                                                            <input
                                                                hidden
                                                                accept="pdf/*"
                                                                multiple
                                                                type="file"
                                                                onChange={event => handleAddFile(event.target, idx)}
                                                            >
                                                            </input>
                                                            <UploadFileIcon />
                                                        </Button>
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}></Grid>
                                    </React.Fragment>
                                )
                            })}
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </Grid>
        </PageCreate>
    );
}
