import React, { useCallback, useEffect, useMemo, useState } from "react";
import PageCreate from "../../components/pageCreate";
import FormPreReceipt from "../../components/forms/formPreReceipt";
import { useSalesOpen } from "../../apis/sales";
import { usePostPrereceipt, postPrereceipt } from "../../apis/prereceipt";
import { Add, Remove, UploadFile } from "@mui/icons-material";
import { useNavigate } from 'react-router-dom';
import {
    Grid,
    TextField,
    Button,
    ButtonGroup,
} from "@mui/material";
import FormCard from "../../components/forms/formCard";

const PrereceiptCreate = () => {
    const base = {
        "valor": "",
        "vendaId": "",
        "files": [],
        "isBlocked": false,
    }

    const navigate = useNavigate();
    const [inputFields, setInputFields] = useState(base);
    const [running, setRunning] = useState(false);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("success");

    const blockSend = useMemo(() => {
        if (inputFields.valor === "" || inputFields.vendaId === "") {
            return true;
        } else {
            return false;
        }
    }, [inputFields]);

    function realToFloat(value) {
        let newValue = value.replace(/\./g, "");
        newValue = newValue.replace(",", ".");
        return parseFloat(newValue);
    }

    const handleAddName = (target, idx) => {
        let filesList = inputFields.files;
        filesList[idx]["field"] = target;
        setInputFields((inputFields) => ({
            ...inputFields, files: filesList
        }));
    };

    const handleAddFile = (target, idx) => {
        let filesList = inputFields.files;
        filesList[idx]["file"] = target.files[0];
        setInputFields((inputFields) => ({
            ...inputFields, files: filesList
        }));
    };

    const handleSubmit = async (e, item) => {
        setRunning(true);
        e.preventDefault();
        const formData = new FormData();
        for (let i = 0; i < item.files.length; i++) {
            if (item.files[i]["file"] !== "" && item.files[i]["field"] !== "") {
                formData.append(item.files[i]["field"], item.files[i]["file"]);
            }
        }
        const newItem = { ...item };
        newItem.valorTotal = realToFloat(item.valor);
        newItem.venda = item.vendaId;
        const jsonBody = JSON.stringify(newItem);
        const blob = new Blob([jsonBody], {
            type: 'application/json'
        });
        formData.append("body", blob);
        const data = await postPrereceipt(formData);
        if (data) {
            setMessage("Pré-Recebimento criado com sucesso!");
            setSeverity("success");
        } else {
            setMessage("Erro ao criar Pré-Recebimento!");
            setSeverity("error");
        }
        setOpen(true);
        setInputFields(base);
        navigate("/prereceiving");
    };

    const { data } = useSalesOpen(10000, 0);

    return (
        <PageCreate title="Novo Pré-Recebimento"
            handleSubmit={(e) => handleSubmit(e, inputFields)}
            message={message}
            severity={severity}
            running={running}
            setRunning={setRunning}
            open={open}
            setOpen={setOpen}
            blockSend={blockSend || inputFields.isBlocked}
        >
            <FormPreReceipt
                inputFields={inputFields}
                setInputFields={setInputFields}
                running={running}
                srcSaleList={data?.content ?? []}
            ></FormPreReceipt>

            <Grid item xs={12} sm={12}>
                <FormCard title="Envio de Arquivos">
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12}>
                            <ButtonGroup disabled={running} style={{ height: "14px" }} variant="outlined" size="small" aria-label="small button group">
                                <Button
                                    disableElevation
                                    onClick={() => {
                                        let filesList = inputFields.files;
                                        filesList.push({ "field": "", "file": "" });
                                        setInputFields({ ...inputFields, files: filesList });
                                    }}
                                >
                                    <Add style={{ fontSize: "12px" }} /></Button>
                                <Button
                                    disableElevation
                                    onClick={() => {
                                        if (inputFields.files.length > 1) {
                                            let filesList = inputFields.files;
                                            filesList.pop();
                                            setInputFields({ ...inputFields, files: filesList });
                                        }
                                    }}
                                >
                                    <Remove style={{ fontSize: "12px" }} /></Button>
                            </ButtonGroup>
                        </Grid>
                        {inputFields.files.map((field, idx) => {
                            return (
                                <React.Fragment key={idx}>
                                    <Grid item xs={12} sm={3}>
                                        <TextField
                                            fullWidth
                                            size="small"
                                            disabled={running}
                                            id={`${idx + 1}`}
                                            name="filename"
                                            label={`Descrição ${idx + 1}`}
                                            value={inputFields.files[idx]["field"]}
                                            onChange={event => handleAddName(event.target.value, idx)}
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <TextField
                                            fullWidth
                                            size="small"
                                            id={`${idx + 1}`}
                                            name="arquivo"
                                            label={`Arquivo ${idx + 1}`}
                                            value={inputFields.files[idx]["file"].name}
                                            InputLabelProps={{ shrink: true }}
                                            InputProps={{
                                                readOnly: true,
                                                endAdornment:
                                                    <Button
                                                        size="small"
                                                        component="label"
                                                        color="primary"
                                                        variant="outlined">
                                                        <input
                                                            hidden
                                                            accept="pdf/*"
                                                            multiple
                                                            type="file"
                                                            onChange={event => handleAddFile(event.target, idx)}
                                                        >
                                                        </input>
                                                        <UploadFile />
                                                    </Button>
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}></Grid>
                                </React.Fragment>
                            )
                        })}
                    </Grid>
                </FormCard>
            </Grid>
        </PageCreate>
    );
}

export default PrereceiptCreate;