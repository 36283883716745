import { Container, Grid, Paper } from '@mui/material';
import React, { useEffect, useState } from "react";
import { formatFloatToReal } from "../../common/utils";
import { isBasic, isUser } from '../../services/auth';

import AccTable from "./accTable";
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import FormCard from "../../components/forms/formCard";
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import PageHeader from "../../components/pageHeader";
import RequestPageIcon from '@mui/icons-material/RequestPage';
import ValueOpenCard from "./cardValue";
import { api } from '../../services/api';
import { useNavigate } from 'react-router-dom';

const Dash = props => {
    const navigate = useNavigate();
    const [bankList, setBankList] = useState([]);
    const [saleDash, setSaleDash] = useState({});
    const [accDash, setAccDash] = useState({});
    const [preReceivingDash, setPreReceivingDash] = useState({});

    useEffect(() => {
        let mounted = true;
        api.getDashboardAccs()
            .then(resp => {
                if (mounted && resp) {
                    setAccDash({ ...resp, totalAccAberto: resp.accTotalContratadoDolar - resp.accTotalRecebidoDolar });
                }
            }
            )

        api.getDashboardSales()
            .then(resp => {
                if (mounted && resp) {
                    setSaleDash({ ...resp, totalVendaAberto: resp.vendaTotalDolar - (resp.vendaTotalRecebidoDolar + resp.vendaTotalPreRecebimento - resp.vendaRecebimentoPreRecebimento) });
                    setPreReceivingDash({ ...resp, totalPreReceiving: resp.vendaTotalPreRecebimento - resp.vendaRecebimentoPreRecebimento });
                }
            }
            )

        api.getDashboardFinInstitutions()
            .then(resp => {
                if (mounted && resp) {
                    setBankList(resp);
                }
            }
            )
        return () => mounted = false;
    }, []);

    React.useEffect(() => {
        (isBasic() || isUser()) ? navigate("/accs") : navigate("/dashboard");
    }, []);

    return (
        <Container maxWidth="xg" sx={{ mt: 2, mb: 2 }}>
            <Grid item xs={12}>
                <PageHeader title="Dashboard"></PageHeader>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={3}>
                    <ValueOpenCard
                        message='Total de ACC em Aberto'
                        values={accDash.totalAccAberto && '$' + formatFloatToReal(accDash.totalAccAberto)}
                    >
                        <RequestPageIcon sx={{ fontSize: 40 }} color='primary' />
                    </ValueOpenCard>
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                    <ValueOpenCard
                        message='Total de Vendas Pendentes de Quitação'
                        values={saleDash.totalVendaAberto && '$' + formatFloatToReal(saleDash.totalVendaAberto)}
                    >
                        <MonetizationOnIcon sx={{ fontSize: 40 }} color='success' />
                    </ValueOpenCard>
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                    <ValueOpenCard
                        message='Pré-Recebimentos'
                        values={preReceivingDash.totalPreReceiving && '$' + formatFloatToReal(preReceivingDash.totalPreReceiving)}
                    >
                        <MonetizationOnIcon sx={{ fontSize: 40 }} color='success' />
                    </ValueOpenCard>
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                    <ValueOpenCard
                        message='Saldo de Cobertura'
                        values={'$' + formatFloatToReal(accDash.totalAccAberto - (saleDash.totalVendaAberto + preReceivingDash.totalPreReceiving))}
                    >
                        <CurrencyExchangeIcon sx={{ fontSize: 40 }} color='warning' />
                    </ValueOpenCard>
                </Grid>

                <Grid item xs={12} sm={12}>
                    <Paper variant="outlined" sx={{ my: { xs: 3, md: 0 }, p: { xs: 2, md: 3 }, borderRadius: '10px' }}>
                        <FormCard title="Relatório de Contratos por Instituição Financeira">
                            <AccTable rows={bankList} />
                        </FormCard>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
}

export default Dash;